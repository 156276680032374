
@media (min-width: 768px)  and (max-width:1400px) {
 
   
    .softskills{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
    }
}