@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
    border: 5px inset #fff;

    background-color: #093040;
    color: #fff;
  }
}

.primary-container {
  .match {
    @include mobile {
      @include poppins;
      font-size: 22px;
      padding: 10px 20px 0 20px;
      text-align: center;
      margin-top: 70px;
      background-image: url("https://frontjobinderimg.s3.amazonaws.com/background-2.jpg");
      background-size: 110% auto;
      background-position: center;
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
    @include tablet {
      @include poppins;
      font-size: 20px;
      text-align: center;
      margin-top: 50px;
      background-image: url("https://frontjobinderimg.s3.amazonaws.com/background-2.jpg");
      background-size: 110% auto;
      background-position: center;
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
    @include desktop {
      @include poppins;
      text-align: center;
      align-items: center;
      text-indent: 100px;
      font-size: 31px;
      line-height: 48px;
      background-image: url("https://frontjobinderimg.s3.amazonaws.com/background-2.jpg");
      background-size: 110% auto;
      background-position: center;
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
  }
  .text-mobile {
    @include mobile {
      @include text-center-mobile();
      font-weight: bold;
      color: #093040;
      padding: 10px 20px 0 20px;
    }
    @include tablet {
      @include text-center-mobile();
      font-weight: bold;
      color: #093040;
    }
  }
}
