@import './_mobile';
.container_image img{
    width: 200px;
    height: 200px;
}
.icon_edit,.icon_check{
    color: #1DD75B;
    font-size: 30px;
   
}

.icon_eyeSlash{
    font-size: 30px;
    color: grey;
}



