@media (min-width: 542px) and (max-width:768px){ 
    // body{
    //     background-color: #ffffff;
    // }
    .softskills{
        h1,h2{
            text-align: center;
            margin-bottom: 25px;
        }
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .col{
        margin-bottom: 50px;
    }
    .table{
        .thead-dark{
            background-color: #FAFAFB;
        }
        
    }
    }
    // .softskills-tableEdit{
    //     width: 10px;
    // }
}