@import "./_mobile";

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-body {
  @include mobile {
  }
  @include tablet {
    display: flex;
    flex-direction: column;
  }
  @include desktop {
  }
  .container_form_General1 {
    @include mobile {
    }
    @include tablet {
    }
    @include desktop {
    }
    .container_image {
      @include mobile {
        .perfil-C {
          width: 50%;
          height: 50%;
          border-radius: 50%;
        }
      }
      @include tablet {
        .perfil-C {
          width: 30%;
          height: 30%;
          border-radius: 50%;
        }
      }
      @include desktop {
        .perfil-C {
          border-radius: 10vw;
          margin-left: 100px;
        }
      }
    }
    .init-form {
      @include mobile {
        margin-left: 35px;
      }
      @include tablet {
      }
      @include desktop {
      }
    }
  }
}
