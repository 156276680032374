@import "../../../Landing/Main/scss/mixins.scss";
.first-column {
  .avatar-url {
  }

  .text-container {
    @include desktop {
      margin-right: 90px;
    }

    .text-info-general {
      @include mobile {
        @include styles(0px, #093040, 40px);
        text-align: center;
        margin-bottom: 20px;
      }
      @include tablet {
        @include styles(15px, #093040, 20px);
        text-align: center !important;
        margin-bottom: 20px !important;
      }
      @include desktop {
        @include styles(15px, #093040, 20px);
      }

      .icons-form-general {
        @include desktop {
          @include styles(0, #498ba6, 0);
          width: 20px;
          height: 20px;
          margin-right: 20px;
        }

        @include tablet {
        }
        @include mobile {
          @include styles(0, #498ba6, 0);
          width: 25px;
          height: 25px;
          margin-right: 20px;
        }
      }
    }

    .btn.btn-outline-info.buscar {
      margin-left: 75px;
      margin-top: 10px;
      @include tablet {
      }
      @include mobile {
      }
    }

    .btn.btn-outline-danger {
    }
  }
}

.second-column {
  .fila-actividades-grl {
    .texto-inf-grl {
      @include styles(20px, #093040, 20px);
      text-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px;
      @include mobile {
        text-align: center;
      }
    }

    .columna-2-actividades-grl {
      .container-actividades-grl {
        .actividad {
          @include styles(10px, #498ba6, 5px);
          @include mobile {
            text-align: center;
            @include styles(10px, #498ba6, 10px);
          }
        }

        .actividades-text-dinamico {
          @extend .actividad;
          @include mobile {
            text-align: center;
          }
        }
      }
    }
  }

  .softskills-solicitadas {
    background-color: #498ba6;
    color: #f2f2f2;
    text-align: center;
    text-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px;
    border-radius: 15px 15px 0px 0px;
    .dev {
      color: #f2f2f2;
      margin-right: 10px;
    }
  }
}
