@import "animate.css";

.main-carousel {
  .carousel-img {
  }

  .carousel-main-img {
  }

  .carousel-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .welcome-text {
      font-size: 100px;
      text-align: center;
      text-transform: to-upper-case($string: "WELCOME TO JOBINDER");
    }
    .carousel-title {
      font-family: "Poppins";
      font-size: 50px;
      font-weight: 50;
    }

    .carousel-content {
      font-family: "Poppins", sans-serif, "Times New Roman", Times, serif;
    }
  }
}
