 @mixin color {
  background: rgba(73, 139, 166, 0.74);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(3.9px);
}


.offcanvas-header {
  @include color();

  .logo-jobinder {
    .img-jobinder {
      margin-bottom: 25px;
      height: 50px;
      width: 150px;
      transition: transform 0.3s ease-in-out;
     
    }
  }
}

.offcanvas-body {
  display: flex;
  justify-content: center;
  @include color();

  .link {
    .icons {
      width: 35px;
      
      color: white;
     
    }
    font-family: 'Poppins';
    margin-bottom: 30px;
    text-decoration: none;
   
    &:hover {
      opacity: 0.7;
      
    }
   

   

    .text {
      font-size: 20px;
      margin-left: 30px;
      color: white;
    }
  }
}  



