@import "../node_modules/bootstrap/scss/bootstrap";
// Importar el archivo SCSS de configuración de Bootstrap

$jobinderColor: #498ba6;
$jobinderHover: #649cb3;
$jobinderColor2: #0b1726;
$jobinderColor3: #093040;
$jobinderColor4: #96c6d9;
$jobinderColor5: #106973;

// Resto de importaciones y definiciones de estilos personalizados

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #498ba6;
  --bs-btn-border-color: #498ba6;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #72acc6;
  --bs-btn-hover-border-color: #72acc6;
  --bs-btn-focus-shadow: 0 0 0 0.25rem #175f7e;
  --bs-btn-active-color: #175f7e;
  --bs-btn-active-bg: #72acc6;
  --bs-btn-active-border-color: #5b97af;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #498ba6;
  --bs-btn-disabled-border-color: #498ba6;
  border-radius: 15px 5px 15px 5px;
  background: var(--bs-btn-bg);
  color: var(--bs-btn-color);
  border: 2px solid var(--bs-btn-border-color);
  padding: 8px 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: inline-block;
}

.btn-primary:hover {
  color: var(--bs-btn-hover-color);
}

.btn-primary::after {
  content: "";
  background: var(--bs-btn-hover-bg);
  position: absolute;
  z-index: -1;
  padding: 16px 20px;
  display: block;
  left: -20%;
  right: -20%;
  top: 0;
  bottom: 0;
  transform: skewX(-45deg) scale(0, 1);
  transition: all 0.3s ease;
}

.btn-primary:hover::after {
  transition: all 0.3s ease-out;
  transform: skewX(-45deg) scale(1, 1);
}

.btn-primary:active {
  color: var(--bs-btn-active-color);
  background: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}

@mixin glassEffect {
  background: rgba(0, 189, 214, 0.18);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
