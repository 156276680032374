

@mixin mobile{
  @media screen and (min-width: 0px) and (max-width: 600px) {
    @content;
    
  }
  }

 @mixin tablet{
  @media screen and (min-width: 601px) and (max-width: 1000px)  {
    @content;
} 
}
@mixin desktop{
    @media screen and (min-width: 1001px)  {
      @content;
    }
}