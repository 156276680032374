.icon_edit,.icon_check1{
    color: #1DD75B;
    font-size: 20px;
}
.icon_eye1{
    font-size: 20px;
}
.icon_eyeSlash1{
    font-size: 20px;
    color: rgb(170, 169, 169);
    border-color: gray;
}
.icon_edit1{
    color: #1DD75B;
    font-size: 20px;
    
   }
