@mixin mobile {
  @media screen and (min-width: 0px) and (max-width: 600px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 601px) and (max-width: 1000px) {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width: 1001px) {
    @content;
  }
}
.nav {
  .container-fluid {
    .toggle {
      display: flex;
      justify-content: flex-start;
      background-color: transparent;
      border: none;
    }

    .logo-img {
      width: 150px;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      background-color: transparent;
    }

    .container-m1 {
      display: flex;
      align-items: center;
      .user-data {
        font-family: "Poppins";
        color: #fff;
        margin-bottom: 0px !important;
        margin-top: 10px;
      }

      .candidate-profile-pic {
        @include mobile() {
          width: 40px;
          height: 40px;
          border-radius: 50px;
          margin-left: 30px;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
          transition: filter 0.3s ease;
          &:hover {
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
            filter: brightness(110%);
          }
        }
        @include tablet() {
          width: 40px;
          height: 40px;
          border-radius: 50px;
          margin-left: 30px;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
          transition: filter 0.3s ease;
          &:hover {
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
            filter: brightness(110%);
          }
        }
        @include desktop() {
          width: 40px;
          height: 40px;
          border-radius: 50px;
          margin-left: 30px;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
          transition: filter 0.3s ease;
          &:hover {
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
            filter: brightness(110%);
          }
        }
      }
    }
  }
}
