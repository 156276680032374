@mixin mobile {
  @media screen and (min-width: 0px) and (max-width: 600px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 601px) and (max-width: 1000px) {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width: 1001px) {
    @content;
  }
}

@mixin center-users {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

@mixin first-cards-containers {
  width: 70%;
  height: 100%;
  font-family: "Poppins", sans-serif, Verdana, Geneva, Tahoma;
}

@mixin letras {
  margin-top: 100px;
  font-family: "Epilogue", sans-serif, Verdana, Geneva, Tahoma;
  align-items: center;
  text-indent: 1em;
  color: #093040;
}

@mixin second-cards-containers {
  width: 70%;
  height: 100%;
  margin-top: 20px;
}
@mixin text-center-mobile {
  margin-top: 2rem;
  text-align: center;
}

@mixin glassEffect {
  background: rgba(0, 189, 214, 0.18);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

@mixin montserrat {
  font: "Montserrat", sans-serif, Verdana, Geneva, Tahoma;
  font-weight: 500;
}

@mixin poppins {
  font-family: "Poppins", sans-serif, Verdana, Geneva, Tahoma;
  font-weight: 400;
}

@mixin cardsSpacing {
  text-align: center;
  margin-top: 20px;
  padding-right: 30px;
  padding-left: 30px;
}

@mixin glow {
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    );
  }

  &:hover::before {
    left: 100%;
  }
}

@mixin styles($padding, $color, $margin-top) {
  padding: $padding;
  color: $color;
  margin-top: $margin-top;
  font-family: "Popins", sans-serif, "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
