.button-2 {
  padding: 0.8em 1.8em;
  border: 2px solid #106973;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: "Poppins", Verdana, Geneva, Tahoma, sans-serif;
  color: #106973;
}

.button-2::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #106973;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.button-2:hover::before {
  width: 105%;
}

.button-2:hover {
  color: #f2f2f2;
}
