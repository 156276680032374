

body {
    background-color: #fff;
    
    .notification {
      position: absolute;
      bottom: 50px;
      left: 50px;
      width: max-content;
      padding: 20px 15px;
      border-radius: 4px;
      background: rgba( 135, 255, 25, 0.43 );
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 7px );
      -webkit-backdrop-filter: blur( 7px );
      border-radius: 10px;
      border: 1px solid rgba( 255, 255, 255, 0.18 );
      color: #9095A0;
      transform: translateY(30px);
      opacity: 0;
      visibility: hidden;
      animation: fade-in 1s linear forwards;
      
      &__progress {
        position: absolute;
        left: 10px;
        bottom: 5px;
        width: calc(100% - 10px);
        height: 3px;
        transform: scaleX(0);
        transform-origin: left;
        background-image: linear-gradient(to right, #539bdb, #3250bf);
        border-radius: inherit;
        animation: load 1s 0.25s linear forwards;
      }
    }
  }
  

@keyframes fade-in {
    5% {
        opacity: 1;
        visibility: visible;
        transform: translate(0);
    }
    90% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes load {
    to {
        transform: scaleX(1);
    }
}